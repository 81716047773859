<template>
  <div
    id="wrapper"
    :class="{ off: nav.fold }"
  >
    <!-- left area start //isAuthenticated-->
    <template v-if="type === 'layout'">
      <div
        class="left_area"
      >
        <!-- left head start -->
        <div class="left_head">
          <a
            href="#"
            class="btn_navi"
            title="메뉴 접기"
            @click.prevent.stop="navFold($event)"
          />
          <h1>mind-on</h1>
        </div>
        <!--// left head end -->

        <!-- navi start : 활성화 a 에 .on 추가 -->
        <ul class="navi">
          <li
            v-for="row in menuList"
            :key="row.menuView"
            @mouseover.prevent.stop="naviMouse($event, row)"
            @mouseout.prevent.stop="naviMouse($event, row)"
          >
            <template v-if="row.children.length>0">
              <a
                href="#"
                :class="{
                  on: (foldCheck && row.active && onCheck)
                    || (!foldCheck&& mouse.over && mouse.children !== false && mouse.children.indexOf(row.menuView) !== -1 )
                    || (!foldCheck&& mouse.over && mouse.children === false && row.menuView == mouse.name),
                  open: row.active,
                  on_view: row.active,
                  'fas fa-building': row.menuView === 'Eap',//EAP 설정
                  'fas fa-user-friends': row.menuView === 'Btoc',//B2C 마인드톡
                  'fas fa-home': row.menuView === 'Con',//상담센터 관리
                  'fas fa-user-tie': row.menuView === 'Expert',//전문가 관리
                  'fas fa-heartbeat': row.menuView === 'Onl',//온라인 심리검사 관리
                  'fas fa-robot': row.menuView === 'Ai',//AI 상담사
                  'fas fa-code': row.menuView === 'Code', //코드 관리
                  'fas fa-user-cog': row.menuView === 'Super', //슈퍼 관리자
                  'fas fa-robot': row.menuView.includes('High'), //고위험군 관리
                  'fas fa-user-friends': row.menuView.includes('Group'), //조직진단 
                }"
                @click.prevent.stop="naviShow($event, row)"
              >
                <span v-if="foldCheck">
                  {{ row.menuName }}
                </span>
                <span v-else-if="!foldCheck && mouse.over">
                  {{ row.menuName }}
                </span>
                <i class="fas fa-angle-down" />
              </a>
              <transition name="slide">
                <div
                  v-if="(foldCheck && row.active) || (!foldCheck && mouse.over)"
                  class="sub_wrap depth2_wrap"
                  :class="{ show: (foldCheck && row.active)}"
                >
                  <ul>
                    <li
                      v-for="subMenu in row.children"
                      :key="subMenu.menuView"
                      @mouseover.prevent.stop="
                        naviMouse($event, subMenu)
                      "
                      @mouseout.prevent.stop="
                        naviMouse($event, subMenu)
                      "
                    >
                      <template v-if="urlCheck(subMenu.url)">
                        <a
                          href="#"
                          :title="subMenu.menuName"
                          @click.prevent.stop="goUrl"
                        >
                          {{ subMenu.menuName }}
                        </a>
                      </template>
                      <template
                        v-else-if="subMenu.children.length>0"
                      >
                        <a
                          href="#"
                          :class="{
                            open: subMenu.active,
                            on: subMenu.active && !foldCheck,
                            on_view: subMenu.active && !foldCheck,
                          }"
                          @click.prevent.stop="
                            naviShow($event, subMenu)
                          "
                        >
                          {{ subMenu.menuName }}
                          <span />
                          <!-- navi2depthShIsActive(subMenu.menuUrl) || subMenu.active -->
                        </a>
                        <transition name="slide">
                          <div
                            v-if="(foldCheck && subMenu.active) || (!foldCheck&& mouse.over && mouse.children == subMenu.menuView && subMenu.menuView.indexOf(mouse.name) != -1 )"
                            class="sub_wrap depth3_wrap show"
                            :style="
                              subMenu.menuView === 'EapCus'
                                ? 'top:0px'
                                : '' || subMenu.menuView === 'EapMng'
                                  ? 'top:33px'
                                  : '' || subMenu.menuView === 'EapSta'
                                    ? 'top:66px'
                                    : ''
                            "
                          >
                            <!-- navi2depthShIsActive(subMenu.menuUrl) || subMenu.active -->
                            <ul>
                              <li
                                v-for="subChildren in subMenu.children"
                                :key="subChildren.menuView"
                                @click.prevent.stop="naviShow($event, subChildren)"
                                @mouseover.prevent.stop="naviMouse($event, subChildren)"
                                @mouseout.prevent.stop="naviMouse($event, subChildren)"
                              >
                                <router-link
                                  :to="{ path: subChildren.url }"
                                >
                                  {{ subChildren.menuName }}
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </transition>
                      </template>
                      <template v-else>
                        <router-link
                          :to="{ path: subMenu.url }"
                          @click.native="
                            naviShow($event, subMenu)
                          "
                        >
                          {{ subMenu.menuName }}
                          <!-- <a
                            href="#"
                            class="menu-show"
                          >
                          </a>-->
                        </router-link>
                      </template>
                    </li>
                  </ul>
                </div>
              </transition>
            </template>
            <template v-else>
              <router-link
                :to="{ path: row.url }"
                :class="{
                  on:(menu.on === row.menuView && foldCheck) ||!foldCheck && mouse.over&& row.menuView == mouse.name,
                  on_view:(menu.currMenu === row.menuView && !foldCheck) ||!foldCheck && mouse.over&& row.menuView == mouse.name,
                  'fas fa-tachometer-alt': row.menuView === 'Dash', // - 대시보드
                }"
                @click="menu.currMenu = row.menuView"
              >
                <span v-show="foldCheck ||!foldCheck && mouse.over&& row.menuView == mouse.name">
                  {{ row.menuName }}
                </span>
              </router-link>
            </template>
            <!--<div class="sub_wrap">
                  <ul>
                    <li>
                      <a href="#">
                        저장소
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        에이전트
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        테마
                      </a>
                    </li>
                  </ul>
                </div>	-->
          </li>
        </ul>
        <!--// navi end -->
      </div>
      <div
        class="right_area"
      >
        <!-- right head start -->
        <div class="right_head">
          <h2 class="float-left">
            <template v-if="menu.navDepth.length>0">
              <template v-for="depth in menu.navDepth">
                {{ depth }} &gt;
              </template>
            </template><strong>{{ menu.navTitle }}</strong>
            <!--<div><span>페이지 설명 삽입</span></div>-->
          </h2>
          <ul>
            <li class="user_setup">
              <button
                type="button"
                class="fas fa-user"
                title="정보 수정"
                data-toggle="modal"
                data-target="#user_info"
              />
            </li>
            <li class="user_info" id="#user_info">
              <span>관리자 ({{ dqMindUserId }})</span>님 환영합니다.
            </li>
            <li class="logout_btn">
              <button
                type="button"
                class="fas fa-sign-out-alt btn_type_gray"
                title="로그아웃"
                @click="logout"
              />
            </li>
          </ul>
        </div>
        <!--<router-link to="/login">
            Login
          </router-link> |
          <router-link to="/about">
            About
          </router-link>-->
        <router-view
          :save-user-data="saveUserData"
          :save-menu-data="saveMenuData"
        />
        <!-- footer start -->
        <div class="dq_footer">
          Copyright © <strong>mind-on</strong>. All rights reserved.
        </div>
        <!--// footer end -->
      </div>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>
<script>
import LoginService from '@/services/LoginService';
import { mapGetters } from "vuex";
import EventBus from '@/eventBus/eventBus.js';
import localData from './api/localData';
import SuperMngAuth from "@/services/super/SuperMngAuth";
import {deleteToken} from "@/utils/FirebaseService";

export default {
  name: "App",
  data() {
    return {
      type: '',
      menu: {
        fold: false,
        currMenu: '',
        on: '',
        onView: '',
        open: false,
        navTitle: '',
        navDepth: [],
      },
      onCheck: false,
      foldCheck: true,
      navTimeout: null,
      menuList: [],
      nav: {
        tree: [],
        loading: true,
        dep: "",
        fold: false,
      },
      mouse:{
        over: false,
        name: null,
        children: false
      },
      naviMouseTimeout: null,
      dqMindUserId:'',
      saveUserData:{
        companyCode: null,
        custId: null,
        deptNm: null,
        email: null,
        inteMbrId: null,
        mbrId: null,
        mbrTp: null,
        modId: null,
        nm: null,
        posNm: null,
        pwd: null,
        roleId: null,
        sign: null,
        tel: null,
        username: null,
      },
      saveMenuData: [

      ],
      tab: {
        main: ['EapCusCs', 'ExpertCs', 'EapStaCon', 'EapMngCha', 'EapMngPay', 'BtocHome', 'CodeCus', 'BtocCs', 'BtocPcf','AiAna', 'BtocSta'],
        sub: ['EapCusCsFaq', 'EapCusCsOto', 'ExpertCsPopup', 'ExpertCsFaq', 'ExpertCsOto', 'EapStaConMap', 'EapStaConExpLic',
          'EapStaConPart','EapStaConMon', 'EapStaConEach', 'EapStaConExp', 'EapMngChaAft', 'EapMngChaBil', 'EapMngPayAft',
          'EapMngPayBil','EapMngPayMail', 'BtocHomeTag', 'BtocHomeAi', 'BtocHomeMen', 'BtocHomeHeaPro', 'BtocHomeHeaCon',
          'BtocHomeSat', 'BtocHomeBan', 'CodeCusDepart', 'BtocCsFaq', 'BtocCsOto', 'BtocPcfAft', 'BtocPcfBil','AiAnaRes', 'BtocStaSa']
      }
    }
  },
  computed:{
     ...mapGetters(["isAuthenticated"]),
  },
  watch:{
      $route: function () {
        this.type = this.$route.meta.type;
        if (this.type === 'layout') {
          this.changeNav();
          const meta = this.$route.meta;
          if(meta.navTitle){
            document.title = 'EAP 관리도구>'+(meta.navDepth ? meta.navDepth.join('>') +'>': '')+meta.navTitle;
          }else{
            document.title = 'EAP 관리도구';
          }
        }else{
          document.title = 'EAP 관리도구';
        }
        if(localData.getUserId() != null){
          this.dqMindUserId = localData.getUserId();
        }
        if(localData.getSaveUserData() != null){
          this.saveUserData = localData.getSaveUserData();
          this.$route.params.saveUserData = this.saveUserData;
        }
        if(localData.getSaveMenuData() != null){
          this.saveMenuData = localData.getSaveMenuData();
          this.$route.params.saveMenuData = this.saveMenuData;
        }
      }
  },
  created(){
    EventBus.$on('login', (data = {}) => {
        this.fetchMenuData();
    });
  },
  mounted() {
    if(this.$route.name !== 'Login' && localData.getUserId()){
      this.dqMindUserId = localData.getUserId();
      this.fetchMenuData();
    }
    this.type = this.$route.meta.type;
    if (this.type === 'layout') {
        this.changeNav();
    }
  },
  methods:{
    fetchMenuData(){
      LoginService.getMyMenuList().then(response => {
        const {data} = response;
        let routeName = this.$route.name;
        if ( Object.keys(this.$route.params).length !== 0 ) {
          if(this.tab.sub.filter(n=>n === routeName).length > 0) {
            this.tab.main.forEach((item, index) => {
              if(routeName.indexOf(item) !== -1) {
                routeName = item;
              }
            })
          }
        }
        // 마인드톡, AI상담사 메뉴 가리기
        data.data = data.data.filter(childrenRow => childrenRow.menuView != 'Btoc' && childrenRow.menuView != 'Ai')
        data.data.forEach((row, index) => {
          row.children.forEach((childrenRow, childrenIndex) => {
            // console.log('row')
            // console.log(row)
            // console.log('childrenRow')
            // console.log(childrenRow)
            if (routeName == childrenRow.menuView) {
              row.active = true;
            }
             if (childrenRow.hasOwnProperty("children")) {
              childrenRow.children.forEach((childrenRow2, childrenIndex2) => {
                if (routeName == childrenRow2.menuView) {
                  row.active = true;
                  childrenRow.active = true;
                }
              })
             }
          })
        })
        this.menuList = data.data;
      }).catch(err => {
          throw new Error(err)
      })
    },
    naviMouse(event, navi){
      if(this.nav.fold && event.type === 'mouseover'){
        clearTimeout(this.naviMouseTimeout);
      }
      this.naviMouseTimeout = setTimeout(() => {
        if(this.nav.fold && event.type === 'mouseover'){
          this.mouse.over = true;
          if(navi.lvl == '1'){
          this.mouse.name = navi.menuView;
          this.mouse.children = false;
          }else if(navi.lvl == '2'){
            this.mouse.name = navi.menuView.split(/(?=[A-Z])/)[0];
            if(navi.hasOwnProperty('children') && navi.children.length>0){
              this.mouse.children = navi.menuView;
            }else{
              this.mouse.children = false;
            }
          }else if(navi.lvl == '3'){
            let childArray = navi.menuView.split(/(?=[A-Z])/);
            childArray.pop();
            this.mouse.children = childArray.join('');
            this.mouse.name = childArray[0];
          }
        }else if(this.nav.fold && event.type === 'mouseout'){
          if(this.mouse.children === false){
            this.mouse.name = null;
          }else{
            this.mouse.children = false;
            this.mouse.name = null;
          }
        }
      })
    },
    naviShow(event, navi){

      if (this.nav.fold && event.type === "click") {
        return false;
      } else if (
        !this.nav.fold &&
        (event.type === "mouseover" || event.type === "mouseout")
      ) {
        return false;
      }
      if (
        (event.type !== "mouseover" && event.type !== "mouseout") ||
        this.nav.fold === true
      ) {
        // 타임아웃 추가
        if (event.type == "mouseover" || event.type == "click") {
          clearTimeout(this.navTimeout);
        }
        this.navTimeout = setTimeout(() => {
          if (navi.hasOwnProperty("active")) {
            if (event.type == "click") {
              navi.active = !navi.active;
              this.onCheck = !this.onCheck;
            } else if (event.type == "mouseout") {
              navi.active = false;

              this.onCheck = false;
            } else if (event.type == "mouseover") {
              navi.active = true;
              this.onCheck = true;
            }
          } else {
            navi.active = true;
            this.onCheck = true;
          }

          if (navi.active) {
            // 다른 메뉴 닫기
            this.menuList.forEach(row => {

              var routeName = this.$route.name;

              if ( Object.keys(this.$route.params).length !== 0 ) {
                if(this.tab.sub.filter(n=>n === routeName).length > 0) {
                  this.tab.main.forEach((item, index) => {
                    if(routeName.indexOf(item) !== -1) {
                      routeName = item;
                    }
                  })
                }
              } else {
              }
              // 1 depth
              if (navi.lvl == '1') {
                if (row.hasOwnProperty("active")) {
                  if (navi.menuView != row.menuView) {
                    row.active = false;
                  }
                }
              }
              row.children.forEach((childRow) => {
                if (navi.lvl == '2') {
                  if (childRow.hasOwnProperty("active")) {
                    if (navi.menuView != childRow.menuView) {
                      childRow.active = false;
                    }
                  }
                }
                if (navi.lvl == '1') {
                  childRow.active = false;
                  if (childRow.hasOwnProperty("children")) {
                    childRow.children.forEach((childRow2) => {
                      if (
                        routeName === childRow2.menuView &&
                        childRow.active === false
                      ) {
                        childRow.active = true;
                      }
                    });
                  }
                }
              });
            });
          }

          this.menuList = JSON.parse(JSON.stringify(this.menuList));
        }, 0);
      }
    },
    navFold(event) {
      this.nav.fold = !this.nav.fold;
      this.foldCheck = !this.foldCheck;
      if (this.foldCheck === false) {
        this.menuList.forEach((row, index) => {
          var routeName = this.$route.name;

          if ( Object.keys(this.$route.params).length !== 0 ) {
            if(this.tab.sub.filter(n=>n === routeName).length > 0) {
              this.tab.main.forEach((item, index) => {
                if(routeName.indexOf(item) !== -1) {
                  routeName = item;
                }
              })
            }
          } else {
          }
          if (row.hasOwnProperty("children")) {
            var rowName = "";
            row.children.forEach((childrenRow, childrenIndex) => {
              if (routeName === childrenRow.menuView) {
                row.active = true;
                rowName = row.menuView;
              } else {
                if (row.menuView !== rowName) {
                  row.active = false;
                } else {
                  row.active = true;
                }
              }
              if (childrenRow.hasOwnProperty("children")) {
                var name = "";
                childrenRow.children.forEach((childrenRow2, childrenIndex2) => {
                  if (routeName === childrenRow2.menuView) {
                    childrenRow.active = true;
                    row.active = true;
                    name = childrenRow.menuView;
                    rowName = row.menuView;
                  } else {
                    if (childrenRow.menuView !== name) {
                      childrenRow.active = false;
                    } else {
                      childrenRow.active = true;
                    }
                  }
                });
              }
            });
          }
        });
      }
    },
    clickMenu(event, row){
        if (
            event.ctrlKey ||
            event.shiftKey ||
            event.metaKey || // apple
            (event.button && event.button == 1) // middle click, >IE9 + everyone else
        ){
            window.open(row.MENU.URL,"_blank");
        }
    },
    changeNav(){
      //let routeName = routeName.charAt(0).toUpperCase() + routeName.slice(1);
      this.menu.currMenu = this.$route.name;
      this.menu.on = this.menu.currMenu;
      this.menu.onView = this.menu.currMenu;
      const meta = this.$route.meta;
      if (meta.navDepth) {
        this.menu.navDepth = meta.navDepth;
      }else{
        this.menu.navDepth = [];
      }
      this.menu.navTitle = meta.navTitle;

      this.menuList.forEach((row, index) => {
          var routeName = this.$route.name;

          if ( Object.keys(this.$route.params).length !== 0 ) {
            if(this.tab.sub.filter(n=>n === routeName).length > 0) {
              this.tab.main.forEach((item, index) => {
                if(routeName.indexOf(item) !== -1) {
                  routeName = item;
                }
              })
            }
          } else {
            if(this.tab.sub.filter(n=>n === routeName).length > 0) {
              this.tab.main.forEach((item, index) => {
                if(routeName.indexOf(item) !== -1) {
                  routeName = item;
                }
              })
            }
          }

          if ( row.hasOwnProperty('children') ) {
            var rowName = '';
            row.children.forEach((childrenRow, childrenIndex) => {
              if ( routeName === childrenRow.menuView ) {row.active = true;
                rowName = row.menuView;
              } else {
                if (row.menuView !== rowName) {
                  row.active = false;
                } else {
                row.active = true;
              }
            }

            if (childrenRow.hasOwnProperty("children")) {
              var name = "";
              childrenRow.children.forEach((childrenRow2, childrenIndex2) => {
                if (routeName === childrenRow2.menuView) {
                  childrenRow.active = true;
                  row.active = true;
                  name = childrenRow.menuView;
                  rowName = row.menuView;
                } else {
                  if (childrenRow.menuView !== name) {
                    childrenRow.active = false;
                  } else {
                    childrenRow.active = true;
                  }
                }
              });
            }
          });
        }
      });

    },
    urlCheck(str) {
      if(str === '/web/ai/info'){
        return true;
      }else{
        return false;
      }
    },
    goUrl(){
      window.open('https://admin.mindon.ai/infochatter3','_blank');
    },
    logout(){
      LoginService.logout().then(response => {
        if(response.data && response.data.logout == true){
            //파이어베이스 알림 제거
            deleteToken(localData.getInteMbrId(),10);
            localData.removeUserId();
            localData.removeSaveUserData();
            localData.removeSaveMenuData();
            this.$router.push({
                  name: 'Login',
              });
        }
      }).catch(err => {
          throw new Error(err)
      })
    },
  }
}
</script>

<style>
.datepicker_icon {
  position: absolute !important;
  top:15px !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* vue select css */
.vs__selected-options {
  width: 0 !important;
}

.vs__selected {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block !important;
  width: 100% !important;
}

.vue-treeselect__control,
.vs__dropdown-toggle {
  color: #333 !important;
  border-radius: 2px !important;
  height: 32px !important;
}
.vs__open-indicator {
  width: 20px;
  height: 20px;
}

.vs__open-indicator:after {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  display: inline-block;
  content: "";
  overflow: hidden;
  width: 20px;
  height: 20px;
  background: url(./assets/img/sp.png) 0 0 no-repeat;
  background-size: 300px auto;
  background-position: 0 -48px;
}
.vue-treeselect__limit-tip {
  display: inline-block;
  padding-top: 3px;
  padding-right: 5px;
  vertical-align: top;
  padding-left:5px;
}
.vue-treeselect__limit-tip-text {
  cursor: default;
  display: block;
  margin: 1px 0 !important;
  padding: 1px 0;
  color: #333 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 30px !important;
}
.vue-treeselect__multi-value-item {
  padding: 1px 0 !important;
}
/*virtual select*/
.vscomp-wrapper{
  font-family:'Noto Sans KR' !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.vscomp-toggle-button{
  color: #333 !important;
  border-radius: 2px !important;
  height: 32px !important;
}
.vscomp-search-container{
  height: 32px !important;
}
.vscomp-search-input{
  font-size: 13px !important;
}
.vscomp-ele {
    max-width: 100% !important;
}
.vscomp-wrapper.focused .vscomp-toggle-button, .vscomp-wrapper:focus .vscomp-toggle-button{
  box-shadow: none !important;
}
/* transition slide */
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 330px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
/* transition fade */

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* slide-out */
.slide-out-mask {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
}

.slide-out {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.5s ease;
}

.slide-out.view-left {
  -webkit-transform: translate3d(-70%, 0, 0);
  transform: translate3d(-70%, 0, 0);
}

.slide-out.view-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slide-out .left {
  background-color: #fff;
  width: 30%;
}

.slide-out .right {
  background-color: #fff;
  width: 70%;
}

.slide-out .left,
.slide-out .right {
  display: inline-block;
}

#slide-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-weight: 100;
  z-index: 2;
}

.slide-out-enter-active {
  animation: slide-out-in 0.5s;
}
.slide-out-leave-active {
  animation: slide-out-in 0.5s reverse;
}
@keyframes slide-out-in {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
}
</style>
