import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import App from '../App.vue'
import localData from '@/api/localData';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/web/dash",
  },
  {
    path: "/web",
    redirect: "/web/dash",
  },
  {
    path: '/web/login',
    name: 'Login',
    component: Login,
    meta: {
      navTitle: '로그인',
    },
    beforeEnter: function (to, from, next) {
      if (localData.getUserId() && localData.getUserId() != null) {
        return next('/');
      }
      next();
    }
  },
  //대시보드
  {
    path: '/web/dash',
    name: 'Dash',
    component: () => import('../views/common/Dash.vue'),
    meta: {
      type: 'layout',
      navTitle: '대시보드',
      authorize: ['Admin']
    },
    props: true,
  },
  // EAP 설정 > EAP 고객
  {
    path: '/web/eap/cus/user',
    name: 'EapCusUser',
    component: () => import('../views/eap/cus/EapCusUser.vue'),
    meta: {
      type: 'layout',
      navTitle: '회원 정보',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/cus/cus',
    name: 'EapCusCus',
    component: () => import('../views/eap/cus/EapCusCus.vue'),
    meta: {
      type: 'layout',
      navTitle: '고객사 정보',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/cus/home',
    name: 'EapCusHome',
    component: () => import('../views/eap/cus/EapCusHome.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/cus/pro',
    name: 'EapCusPro',
    component: () => import('../views/eap/cus/EapCusPro.vue'),
    meta: {
      type: 'layout',
      navTitle: '프로젝트 정보',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/cus/cs',
    name: 'EapCusCs',
    component: () => import('../views/eap/cus/EapCusCs.vue'),
    meta: {
      type: 'layout',
      navTitle: '고객센터',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/cus/cs/faq',
    name: 'EapCusCsFaq',
    component: () => import('../views/eap/cus/EapCusFaq.vue'),
    meta: {
      type: 'layout',
      navTitle: 'FAQ',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/cus/cs/oto',
    name: 'EapCusCsOto',
    component: () => import('../views/eap/cus/EapCusCsOto.vue'),
    meta: {
      type: 'layout',
      navTitle: '1:1 문의',
      navDepth: ['EAP 설정', 'EAP 고객'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  // EAP 설정 > EAP 관리
  {
    path: '/web/eap/mng/app',
    name: 'EapMngApp',
    component: () => import('../views/eap/mng/EapMngApp.vue'),
    meta: {
      type: 'layout',
      navTitle: '신청서 작성',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/ass',
    name: 'EapMngAss',
    component: () => import('../views/eap/mng/EapMngAss.vue'),
    meta: {
      type: 'layout',
      navTitle: '배정 & 상담기록부',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/cri',
    name: 'EapMngCri',
    component: () => import('../views/eap/mng/EapMngCri.vue'),
    meta: {
      type: 'layout',
      navTitle: '위기사례 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/rev',
    name: 'EapMngRev',
    component: () => import('../views/eap/mng/EapMngRev.vue'),
    meta: {
      type: 'layout',
      navTitle: '후기 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/hea',
    name: 'EapMngHea',
    component: () => import('../views/eap/mng/EapMngHea.vue'),
    meta: {
      type: 'layout',
      navTitle: '힐링 프로그램 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/cha',
    name: 'EapMngCha',
    component: () => import('../views/eap/mng/EapMngCha.vue'),
    meta: {
      type: 'layout',
      navTitle: '청구 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/cha/aft',
    name: 'EapMngChaAft',
    component: () => import('../views/eap/mng/EapMngChaAft.vue'),
    meta: {
      type: 'layout',
      navTitle: '청구 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/cha/bil',
    name: 'EapMngChaBil',
    component: () => import('../views/eap/mng/EapMngChaBil.vue'),
    meta: {
      type: 'layout',
      navTitle: '청구 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/pay',
    name: 'EapMngPay',
    component: () => import('../views/eap/mng/EapMngPay.vue'),
    meta: {
      type: 'layout',
      navTitle: '지급 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/pay/aft',
    name: 'EapMngPayAft',
    component: () => import('../views/eap/mng/EapMngPayAft.vue'),
    meta: {
      type: 'layout',
      navTitle: '지급 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/pay/bil',
    name: 'EapMngPayBil',
    component: () => import('../views/eap/mng/EapMngPayBil.vue'),
    meta: {
      type: 'layout',
      navTitle: '지급 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/pay/mail',
    name: 'EapMngPayMail',
    component: () => import('../views/eap/mng/EapMngPayMail.vue'),
    meta: {
      type: 'layout',
      navTitle: '지급 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/cs',
    name: 'EapMngCs',
    component: () => import('../views/eap/mng/EapMngCs.vue'),
    meta: {
      type: 'layout',
      navTitle: 'EAP고객 공지사항',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/mng/sms',
    name: 'EapMngSms',
    component: () => import('../views/eap/mng/EapMngSms.vue'),
    meta: {
      type: 'layout',
      navTitle: '자동 발송 문자 관리',
      navDepth: ['EAP 설정', 'EAP 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },


  // EAP 설정 > EAP 통계
  {
    path: '/web/eap/sta/ind',
    name: 'EapStaInd',
    component: () => import('../views/eap/sta/EapStaInd.vue'),
    meta: {
      type: 'layout',
      navTitle: '기본 이용 현황',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/det',
    name: 'EapStaDet',
    component: () => import('../views/eap/sta/EapStaDet.vue'),
    meta: {
      type: 'layout',
      navTitle: '상세 이용 현황',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/per',
    name: 'EapStaPer',
    component: () => import('../views/eap/sta/EapStaPer.vue'),
    meta: {
      type: 'layout',
      navTitle: '성과 지표',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/com',
    name: 'EapStaCom',
    component: () => import('../views/eap/sta/EapStaCom.vue'),
    meta: {
      type: 'layout',
      navTitle: '주호소 문제',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/eme',
    name: 'EapStaEme',
    component: () => import('../views/eap/sta/EapStaEme.vue'),
    meta: {
      type: 'layout',
      navTitle: '위기관리 현황',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/con',
    name: 'EapStaCon',
    component: () => import('../views/eap/sta/EapStaCon.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/con/map',
    name: 'EapStaConMap',
    component: () => import('../views/eap/sta/EapStaConMap.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/con/explic',
    name: 'EapStaConExpLic',
    component: () => import('../views/eap/sta/EapStaConExpLic.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  {
    path: '/web/eap/sta/con/part',
    name: 'EapStaConPart',
    component: () => import('../views/eap/sta/EapStaConPart.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  {
    path: '/web/eap/sta/con/mon',
    name: 'EapStaConMon',
    component: () => import('../views/eap/sta/EapStaConMon.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/con/each',
    name: 'EapStaConEach',
    component: () => import('../views/eap/sta/EapStaConEach.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/con/exp',
    name: 'EapStaConExp',
    component: () => import('../views/eap/sta/EapStaConExp.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 및 전문가 통계',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/eap/sta/rep',
    name: 'EapStaRep',
    component: () => import('../views/eap/sta/EapStaRep.vue'),
    meta: {
      type: 'layout',
      navTitle: '운영 보고서',
      navDepth: ['EAP 설정', 'EAP 통계'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  // B2C 마인드톡
  {
    path: '/web/btoc/user',
    name: 'BtocUser',
    component: () => import('../views/btoc/BtocUser.vue'),
    meta: {
      type: 'layout',
      navTitle: '회원정보',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home',
    name: 'BtocHome',
    component: () => import('../views/btoc/BtocHome.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  {
    path: '/web/btoc/home/tag',
    name: 'BtocHomeTag',
    component: () => import('../views/btoc/BtocHomeTag.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home/ai',
    name: 'BtocHomeAi',
    component: () => import('../views/btoc/BtocHomeAi.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home/men',
    name: 'BtocHomeMen',
    component: () => import('../views/btoc/BtocHomeMen.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home/heapro',
    name: 'BtocHomeHeaPro',
    component: () => import('../views/btoc/BtocHomeHeaPro.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home/heacon',
    name: 'BtocHomeHeaCon',
    component: () => import('../views/btoc/BtocHomeHeaCon.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home/sat',
    name: 'BtocHomeSat',
    component: () => import('../views/btoc/BtocHomeSat.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/home/ban',
    name: 'BtocHomeBan',
    component: () => import('../views/btoc/BtocHomeBan.vue'),
    meta: {
      type: 'layout',
      navTitle: '홈페이지 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/cou',
    name: 'BtocCou',
    component: () => import('../views/btoc/BtocCou.vue'),
    meta: {
      type: 'layout',
      navTitle: '쿠폰 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/cs',
    name: 'BtocCs',
    component: () => import('../views/btoc/BtocCs.vue'),
    meta: {
      type: 'layout',
      navTitle: 'B2C 고객센터',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  {
    path: '/web/btoc/cs/faq',
    name: 'BtocCsFaq',
    component: () => import('../views/btoc/BtocCsFaq.vue'),
    meta: {
      type: 'layout',
      navTitle: 'B2C 고객센터',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  {
    path: '/web/btoc/cs/oto',
    name: 'BtocCsOto',
    component: () => import('../views/btoc/BtocCsOto.vue'),
    meta: {
      type: 'layout',
      navTitle: 'B2C 고객센터',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/vou',
    name: 'BtocVou',
    component: () => import('../views/btoc/BtocVou.vue'),
    meta: {
      type: 'layout',
      navTitle: '이용권 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/pay',
    name: 'BtocPay',
    component: () => import('../views/btoc/BtocPay.vue'),
    meta: {
      type: 'layout',
      navTitle: '결제 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/com',
    name: 'BtocCom',
    component: () => import('../views/btoc/BtocCom.vue'),
    meta: {
      type: 'layout',
      navTitle: '커뮤니티 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/rev',
    name: 'BtocRev',
    component: () => import('../views/btoc/BtocRev.vue'),
    meta: {
      type: 'layout',
      navTitle: '후기 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/hea',
    name: 'BtocHea',
    component: () => import('../views/btoc/BtocHea.vue'),
    meta: {
      type: 'layout',
      navTitle: '힐링 프로그램 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/pcf',
    name: 'BtocPcf',
    component: () => import('../views/btoc/BtocPcf.vue'),
    meta: {
      type: 'layout',
      navTitle: '상담료 지급 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/pcf/aft',
    name: 'BtocPcfAft',
    component: () => import('../views/btoc/BtocPcfAft.vue'),
    meta: {
      type: 'layout',
      navTitle: '상담료 지급 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/btoc/pcf/bil',
    name: 'BtocPcfBil',
    component: () => import('../views/btoc/BtocPcfBil.vue'),
    meta: {
      type: 'layout',
      navTitle: '상담료 지급 관리',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  // 운영통계 - 회원가입 통계
  {
    path: '/web/btoc/sta',
    name: 'BtocSta',
    component: () => import('../views/btoc/BtocSta.vue'),
    meta: {
      type: 'layout',
      navTitle: '운영 통계',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  // 운영통계 - 판매 통계
  {
    path: '/web/btoc/sta/sa',
    name: 'BtocStaSa',
    component: () => import('../views/btoc/BtocStaSa.vue'),
    meta: {
      type: 'layout',
      navTitle: '운영 통계',
      navDepth: ['B2C 마인드톡'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  // 상담센터 관리
  {
    path: '/web/con/con',
    name: 'ConCon',
    component: () => import('../views/consult/ConCon.vue'),
    meta: {
      type: 'layout',
      navTitle: '협약 센터 정보',
      navDepth: ['상담센터 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/con/com',
    name: 'ConCom',
    component: () => import('../views/consult/ConCom.vue'),
    meta: {
      type: 'layout',
      navTitle: '사내 상담센터 정보',
      navDepth: ['상담센터 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/con/res',
    name: 'ConRes',
    component: () => import('../views/consult/ConRes.vue'),
    meta: {
      type: 'layout',
      navTitle: '상담 예약 관리',
      navDepth: ['상담센터 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/con/rec',
    name: 'ConRec',
    component: () => import('../views/consult/ConRec.vue'),
    meta: {
      type: 'layout',
      navTitle: '권역/추천 센터 설정',
      navDepth: ['상담센터 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  // 전문가 관리
  {
    path: '/web/expert/info',
    name: 'ExpertInfo',
    component: () => import('../views/expert/ExpertInfo.vue'),
    meta: {
      type: 'layout',
      navTitle: '외부 전문가 정보',
      navDepth: ['전문가 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/cominfo',
    name: 'ExpertCominfo',
    component: () => import('../views/expert/ExpertComInfo.vue'),
    meta: {
      type: 'layout',
      navTitle: '사내 전문가 정보',
      navDepth: ['전문가 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/emp',
    name: 'ExpertEmp',
    component: () => import('../views/expert/ExpertEmp.vue'),
    meta: {
      type: 'layout',
      navTitle: '채용 관리',
      navDepth: ['전문가 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/cs',
    name: 'ExpertCs',
    component: () => import('../views/expert/ExpertCs.vue'),
    meta: {
      type: 'layout',
      navTitle: '공지사항',
      navDepth: ['전문가 관리', '고객센터'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/cs/popup',
    name: 'ExpertCsPopup',
    component: () => import('../views/expert/ExpertCsPopup.vue'),
    meta: {
      type: 'layout',
      navTitle: '팝업창',
      navDepth: ['전문가 관리', '고객센터'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/cs/faq',
    name: 'ExpertCsFaq',
    component: () => import('../views/expert/ExpertCsFaq.vue'),
    meta: {
      type: 'layout',
      navTitle: 'FAQ',
      navDepth: ['전문가 관리', '고객센터'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/cs/oto',
    name: 'ExpertCsOto',
    component: () => import('../views/expert/ExpertCsOto.vue'),
    meta: {
      type: 'layout',
      navTitle: '1:1 문의',
      navDepth: ['전문가 관리', '고객센터'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/expert/sta',
    name: 'ExpertEmp',
    component: () => import('../views/expert/ExpertSta.vue'),
    meta: {
      type: 'layout',
      navTitle: '바로상담 설정',
      navDepth: ['전문가 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  // 온라인 심리검사 관리
  {
    path: '/web/onl/ind',
    name: 'OnlInd',
    component: () => import('../views/onl/OnlInd.vue'),
    meta: {
      type: 'layout',
      navTitle: '개별 검사 관리',
      navDepth: ['온라인 심리검사 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/onl/int',
    name: 'OnlInt',
    component: () => import('../views/onl/OnlInt.vue'),
    meta: {
      type: 'layout',
      navTitle: '통합 검사 관리',
      navDepth: ['온라인 심리검사 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/onl/ind/resPop',
    name: 'OnlResPop',
    component: () => import('../views/onl/ResPop.vue'),
    meta: {
      navTitle: '개별 검사 관리',
      navDepth: ['온라인 심리검사 관리'],
      authorize: ['Admin']
    },
    props:true,
/*    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }*/
  },
  // AI 상담사
  {
    path: '/web/ai/ana',
    name: 'AiAna',
    component: () => import('../views/ai/AiAna'),
    meta: {
      type: 'layout',
      navTitle: 'AI 상담분석',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/ai/ana/res',
    name: 'AiAnaRes',
    component: () => import('../views/ai/AiAnaRes'),
    meta: {
      type: 'layout',
      navTitle: 'AI 상담분석',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/ai/info',
    name: 'AiInfo',
    component: () => import('../views/ai/AiInfo.vue'),
    meta: {
      type: 'layout',
      navTitle: '인포채터 바로가기',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  // 코드관리
  {
    path: '/web/code/mng',
    name: 'CodeMng',
    component: () => import('../views/code/CodeMng.vue'),
    meta: {
      type: 'layout',
      navTitle: '표준 코드 관리 ',
      navDepth: ['코드 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/code/cus',
    name: 'CodeCus',
    component: () => import('../views/code/CodeCusJob.vue'),
    meta: {
      type: 'layout',
      navTitle: '고객사 코드 관리',
      navDepth: ['코드 관리'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/code/cus/dep',
    name: 'CodeCusDepart',
    component: () => import('../views/code/CodeCusDepart.vue'),
    meta: {
      type: 'layout',
      navTitle: '고객사 코드 관리',
      navDepth: ['코드 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/code/cri',
    name: 'CodeCri',
    component: () => import('../views/code/CodeCri'),
    meta: {
      type: 'layout',
      navTitle: '위기감지 코드 관리',
      navDepth: ['코드 관리'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/super/mng',
    name: 'SuperMng',
    component: () => import('../views/super/SuperMng.vue'),
    meta: {
      type: 'layout',
      navTitle: '운영자 관리',
      navDepth: ['슈퍼 관리자'],
      authorize: ['Admin']
    },
    props: true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },

  // 고위험군관리
  {
    path: '/web/high/pro',
    name: 'HighPro',
    /*
    component: () => import('../views/high/HighPro.vue'),
    meta: {
      type: 'layout',
      navTitle: '검사프로젝트관리',
      navDepth: ['고위험군관리'],
      authorize: ['Admin']
    },
    props: true,*/
    component: () => import('../views/high/HighPro.vue'),
    beforeEnter: () => {
      window.location.href = "https://test.mindon.co/mpro?id=1";
    },
    // beforeEnter: (to, from, next) => {
    //   to.matched.some((routeInfo) => {
    //     if(localData.getUserMenuAuth(routeInfo.name)) {
    //       next();
    //     }else {
    //       next('/dash');
    //       console.log(routeInfo);          
    //       alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
    //     }
    //   })
    // }
  },
  {
    path: '/web/high/mind',
    name: 'HighMind',
    component: () => import('../views/high/HighMind.vue'),
    beforeEnter: () => {
      window.location.href = "https://test.mindon.co/mpro?id=2";
    },
    // meta: {
    //   type: 'layout',
    //   navTitle: '마음 안전 대시보드',
    //   navDepth: ['고위험군관리'],
    //   authorize: ['Admin']
    // },
    // props: true,
    // beforeEnter: (to, from, next) => {
    //   to.matched.some((routeInfo) => {
    //     if(localData.getUserMenuAuth(routeInfo.name)) {
    //       next();
    //     }else {
    //       next('/dash');
    //       alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
    //     }
    //   })
    // }
  },

  // 조직진단
  {
    path: '/web/group/result',
    name: 'GroupResult',
    component: () => import('../views/group/GroupResult.vue'),
    beforeEnter: () => {
      window.location.href = "https://test.mindon.co/mpro?id=3";
    }
    // meta: {
    //   type: 'layout',
    //   navTitle: '조직진단 결과 요약',
    //   navDepth: ['조직진단'],
    //   authorize: ['Admin']
    // },
    // props: true,
    // beforeEnter: (to, from, next) => {
    //   to.matched.some((routeInfo) => {
    //     if(localData.getUserMenuAuth(routeInfo.name)) {
    //       next();
    //     }else {
    //       next('/dash');
    //       alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
    //     }
    //   })
    // }
  },
  {
    path: '/web/group/report',
    name: 'GroupReport',
    component: () => import('../views/group/GroupReport.vue'),
    beforeEnter: () => {
      window.location.href = "https://test.mindon.co/mpro?id=4";
    }
    // meta: {
    //   type: 'layout',
    //   navTitle: '조직진단 보고서',
    //   navDepth: ['조직진단'],
    //   authorize: ['Admin']
    // },
    // props: true,
    // beforeEnter: (to, from, next) => {
    //   to.matched.some((routeInfo) => {
    //     if(localData.getUserMenuAuth(routeInfo.name)) {
    //       next();
    //     }else {
    //       next('/dash');
    //       alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
    //     }
    //   })
    // }
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/web/dash",
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'on active'
})

export default router
